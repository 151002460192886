import { FC, memo, useCallback } from 'react'
import { SwiperSlide } from 'swiper/react'

import { Category, ICategoryProps } from '@obeta/components/lib/category-section/Category'
import { SwiperSlider } from '@obeta/components/lib/swiper-slider/SwiperSlider'

import { IObetaCategory } from './types'
import styles from './CategorySection.module.scss'
import { useChangeSearchParams } from '@obeta/data/lib/hooks/useChangeSearchParams'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'

export interface ICategorySectionProps {
  obetaCategory: IObetaCategory[]
}

interface ICategorySectionLayoutProps extends ICategorySectionProps {
  onCategoryClick: ICategoryProps['onCategoryClicked']
}

const CategorySectionSliderLayout: FC<ICategorySectionLayoutProps> = memo(
  ({ obetaCategory, onCategoryClick }) => {
    const spaceBetween = useRemToPx(0.5)

    return (
      <div className={styles['swiperContainer']}>
        <SwiperSlider
          classes={{ swiperElement: styles.swiperElement }}
          options={{ slidesPerView: 'auto', spaceBetween, observer: true, resizeObserver: true }}
        >
          {obetaCategory.map((cat) => (
            <SwiperSlide key={cat.name} className={styles.swiperSlide}>
              <Category {...cat} onCategoryClicked={onCategoryClick} />
            </SwiperSlide>
          ))}
        </SwiperSlider>
      </div>
    )
  }
)

const CategorySectionGridLayout: FC<ICategorySectionLayoutProps> = ({
  obetaCategory,
  onCategoryClick,
}) => (
  <div className={styles.categories}>
    {obetaCategory.map((cat) => (
      <div className={styles.category} key={cat.name}>
        <Category {...cat} onCategoryClicked={onCategoryClick} />
      </div>
    ))}
  </div>
)

export const CategorySection: FC<ICategorySectionProps> = (props) => {
  const { obetaCategory } = props
  const changeSearchParams = useChangeSearchParams()
  const { mobile } = useBreakpoints()

  const onCategoryClicked = useCallback(
    (id, name) => {
      changeSearchParams({
        searchParams: {
          obetaCategory: { id, name, rootId: id, level: 0 },
        },
        route: 'push',
      })
    },
    [changeSearchParams]
  )

  if (mobile) {
    return (
      <CategorySectionSliderLayout
        obetaCategory={obetaCategory}
        onCategoryClick={onCategoryClicked}
      />
    )
  }

  return (
    <CategorySectionGridLayout obetaCategory={obetaCategory} onCategoryClick={onCategoryClicked} />
  )
}
